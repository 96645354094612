import XIcon from '@demandscience/ui/icons/x';
import ChevronDownIcon from '@demandscience/ui/icons/chevron-down';

import { ExportDestination, exportDestinations, ExportsFilter, ModelType, TimePeriod } from 'types';

import Dropdown from 'components/Filter/Dropdown';
import { includes, isEmpty, isEqual, join, map, omit, reduce } from 'lodash';
import Search from 'components/Input/Search';
import classNames from 'classnames';
import CheckboxMenuItem from 'components/Filter/Dropdown/CheckboxMenuItem';
import DividerMenuItem from 'components/Filter/Dropdown/DividerMenuItem';
import TextMenuItem from 'components/Filter/Dropdown/TextMenuItem';
import { useCallback, useRef, useState } from 'react';
import Datepicker from 'components/DatePicker/DatePicker';
import { formatISO } from 'date-fns';

export const timePeriod = {
  today: 'Today',
  'last 7 days': 'Last 7 days',
  'last 30 days': 'Last 30 days',
  'this month': 'This month',
  'last month': 'Last month',
};
const LABELS: Record<string, string> = {
  [ModelType.Company]: 'Companies',
  [ModelType.Contact]: 'Contacts',
  ...exportDestinations,
  ...timePeriod,
  custom: 'Custom',
};

const getLabel = (value: any[]) => {
  if (isEqual(value, ['all'])) {
    return 'All exports';
  }

  return join(
    map(value, (key: string) => LABELS[key]),
    ', ',
  );
};

const getSelectValue = (value?: ExportsFilter) => {
  let res: Array<'all' | ModelType | ExportDestination | TimePeriod> = [];
  if (
    isEmpty(value?.record_types) &&
    isEmpty(value?.destinations) &&
    isEmpty(value?.time_period) &&
    isEmpty(value?.date_from && value?.date_to)
  ) {
    res = ['all'];
  } else {
    if (value?.record_types) {
      res = [...res, ...value.record_types];
    }
    if (value?.destinations) {
      res = [...res, ...value.destinations];
    }
    if (value?.time_period) {
      res = [...res, value.time_period];
    }
    if (value?.date_from && value?.date_to) {
      res = [...res, 'custom'];
    }
  }

  return res;
};

interface FiltersProps {
  onChange: (value: ExportsFilter) => void;
  value?: ExportsFilter;
}

const Filters: React.FC<FiltersProps> = ({ value, onChange }) => {
  const selectValue = getSelectValue(value);

  const chevronRef = useRef<HTMLButtonElement | null>(null);

  const [openDatepicker, setOpenDatepicker] = useState(false);

  const [dates, setDates] = useState<[string | null, string | null]>([null, null]);

  const handleDateChange = useCallback((newDates: [string | null, string | null]) => {
    setDates(newDates);
  }, []);

  const handleCustomConfirm = () => {
    const [dateFrom, dateTo] = dates;

    // Format the dates in ISO format
    if (!dateFrom || !dateTo) {
      return;
    }
    const formattedDateFrom = formatISO(new Date(dateFrom));
    const formattedDateTo = formatISO(new Date(dateTo));

    handleSelectChange(['custom', { date_from: formattedDateFrom, date_to: formattedDateTo }]);
  };

  const handleChange = useCallback(
    (field: string, fieldValue: any) => {
      if (isEmpty(fieldValue)) {
        onChange(omit(value, field));
      } else {
        onChange({
          ...value,
          [field]: fieldValue,
        });
      }
    },
    [onChange, value],
  );

  const handleSearchChange = useCallback(
    (freetext: string | undefined) => {
      handleChange('account_freetext', freetext);
    },
    [handleChange],
  );

  const handleSelectChange = useCallback(
    (newValue: any) => {
      // Handle case where user selects 'all' or clears the selection
      if (isEmpty(newValue) || (!includes(selectValue, 'all') && includes(newValue, 'all'))) {
        setOpenDatepicker(false);
        onChange(
          omit(value, 'record_types', 'destinations', 'time_period', 'date_from', 'date_to'),
        );
      } else {
        let hasCustom = false;
        let customFilter: any = null;

        const addedValues = newValue.filter((val: any) => !includes(selectValue, val));
        // const removedValues = selectValue.filter((val: any) => !includes(newValue, val));

        const timePeriodKeys = Object.keys(timePeriod);

        const addedCustom = includes(addedValues, 'custom');
        const addedTimePeriod = addedValues.find((val: any) => includes(timePeriodKeys, val));

        if (addedCustom) {
          newValue = newValue.filter((val: any) => !includes(timePeriodKeys, val));
        }

        if (addedTimePeriod) {
          newValue = newValue.filter((val: any) => val !== 'custom');
          delete value?.date_from;
          delete value?.date_to;
        }

        const res = reduce(
          newValue,
          (res, val) => {
            if (val === 'all') {
              setOpenDatepicker(false);
              return res;
            }

            if (val === 'custom') {
              hasCustom = true;
              return res;
            }

            if (typeof val === 'object' && val.date_from && val.date_to) {
              customFilter = val;
              return res;
            }

            let key: keyof ExportsFilter;
            let formattedValue = val;

            if (Object.keys(timePeriod).includes(val)) {
              key = 'time_period';
              formattedValue = val;
              setOpenDatepicker(false);
              return {
                ...omit(res, 'time_period'),
                [key]: formattedValue,
              };
            } else if (includes(ModelType, val)) {
              key = 'record_types';
            } else {
              key = 'destinations';
            }

            const existingValue = res[key];
            const newValueArray = Array.isArray(existingValue)
              ? [...existingValue, formattedValue]
              : [formattedValue];

            return {
              ...res,
              [key]: newValueArray,
            };
          },
          {} as ExportsFilter,
        );

        if (hasCustom) {
          delete res.time_period;

          if (customFilter && customFilter.date_from && customFilter.date_to) {
            onChange({
              ...omit(value, 'time_period', 'date_from', 'date_to'),
              ...res,
              date_from: customFilter.date_from,
              date_to: customFilter.date_to,
            });
          } else if (value?.date_from && value.date_to) {
            onChange({
              ...omit(value, 'time_period', 'date_from', 'date_to'),
              ...res,
              date_from: value.date_from,
              date_to: value.date_to,
            });
          } else {
            if (chevronRef.current) {
              chevronRef.current.click(); // Close the dropdown
            }
            setOpenDatepicker(true); // Open the date picker
          }
        } else {
          onChange({
            ...omit(value, 'record_types', 'destinations', 'time_period', 'date_from', 'date_to'),
            ...res,
          });
        }

        if (!includes(newValue, 'custom')) {
          setOpenDatepicker(false);
        }
      }
    },
    [onChange, selectValue, value],
  );

  const handleClearAll = useCallback(() => {
    onChange({});
    setOpenDatepicker(false);
  }, [onChange]);

  return (
    <>
      <div className="md:w-64">
        <Search
          placeholder="Filter list by team member"
          value={value?.account_freetext}
          onChange={handleSearchChange}
        />
      </div>
      <Dropdown
        menuClassName="max-h-auto"
        value={selectValue}
        onChange={handleSelectChange}
        multiple
        matchContentSize
        button={({ open, value }) => (
          <button
            // ref={ref}
            ref={chevronRef}
            className="px-4 py-2 text-sm text-gray-700 text-left w-full md:w-52 flex flex-row items-center gap-1 border-none rounded ring-inset ring-1 ring-gray-300 hover:ring-gray-700 focus:ring-primary-500 focus:hover:ring-primary-500 focus:outline-none"
          >
            <div className="grow ellipsis">{getLabel(value)}</div>
            {!isEqual(value, ['all']) && (
              <XIcon size={18} className="flex-shrink-0 text-gray-500" onClick={handleClearAll} />
            )}
            <ChevronDownIcon
              size={18}
              className={classNames('flex-shrink-0', { 'rotate-180': open })}
            />
          </button>
        )}
      >
        <CheckboxMenuItem option={{ id: 'all', label: 'All exports', value: 'all' }} />
        <DividerMenuItem />
        <TextMenuItem textClassName="text-xs text-gray-500 py-[2px]">Export type</TextMenuItem>
        {map([ModelType.Company, ModelType.Contact], (value) => (
          <CheckboxMenuItem key={value} option={{ id: value, label: LABELS[value], value }} />
        ))}
        <DividerMenuItem />
        <TextMenuItem textClassName="text-xs text-gray-500 py-[2px]">
          Export destination
        </TextMenuItem>
        {map(exportDestinations, (label, value) => (
          <CheckboxMenuItem key={value} option={{ id: value, label, value }} />
        ))}
        <DividerMenuItem />
        <TextMenuItem textClassName="text-xs text-gray-500 py-[2px]">Date</TextMenuItem>
        <CheckboxMenuItem option={{ id: 'custom', label: 'Custom', value: 'custom' }} />
        {map(timePeriod, (label, value) => (
          <CheckboxMenuItem key={value} option={{ id: value, label, value }} />
        ))}
      </Dropdown>
      {openDatepicker && (
        <Datepicker
          onChange={handleDateChange}
          onConfirm={handleCustomConfirm}
          limitStartDate={new Date(1880, 0, 1)} // Optional limit to Jan 1, 1880, to prevent users from entering dates before 1880
        />
      )}
    </>
  );
};

export default Filters;
